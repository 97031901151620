@import "bootstrap-5.x/scss/_functions.scss";
@import "bootstrap-5.x/scss/_variables.scss";
@import "bootstrap-5.x/scss/bootstrap.scss";

.debug {

	&,
	& * {
		outline: solid 1px rgba(255, 0, 255, .2);
	}
}

:root {
	font-family: 'DM Sans', sans-serif;
}

html,
body,
#root {
	height: 100%;
}

svg.ibdn-logo {
	fill: #0066ff;
}

@media (min-width: 651px) {

	.ce-block__content,
	.ce-toolbar__content {
		max-width: calc(100% - 120px) !important;
		margin: 0 60px;
	}
}

.ce-toolbar {
	margin-left: -10px;

	.ce-toolbar__actions {
	}
}

.cdx-block {
	max-width: 100% !important;
}

div.codex-editor__redactor {
	padding-bottom: 2rem !important;
}


.text-left {
	align-items: start !important;
	text-align: left !important;
}



//  sidebar 
.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	width: 15vw;
	min-width: 170px;
	height: 100vh;
	color: #36383D;
	background-color: #F5F6F7;
	border-right: 1px solid #EBECED;

	button {
		color: #36383D;
	}

	.subtitle {
		font-size: 80%;
		font-weight: bold; 
		text-transform: uppercase;
		opacity: 0.7;
	}

	.active {
		background: #E5E6E7;
	}

	.sidebar-footer {
		border-top: 1px solid #EBECED;
	}
}
.left-padding { 
	width: 16vw;
	min-width: 180px;
}



.pointer-events-none,
.pointer-events-none * {
	pointer-events: none !important;
}

.Mui-error {
	// background-color: rgba(255, 0, 0, .1);

	svg {
		fill: rgba(255, 0, 0, .5);
	}

	input::placeholder {
		opacity: 1;
		color: rgba(255, 0, 0, .5);
	}
}

.disabled {
	opacity: .3;

	&,
	& * {
		pointer-events: none;
	}
}

@media print {

	.break-before {
		page-break-before: always;
	}

	.break-after {
		page-break-after: always;
	}

	.avoid-break {
		page-break-inside: avoid;
	}

	.no-print {
		display: none !important;
	}

	th,
	tr,
	td,
	canvas,
	img,
	span,
	label {
		page-break-inside: avoid;
	}
}